<template>
  <v-container
    id="predicao-historico"
    fluid
    tag="section"
  >

    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-text >
          <p> Quer mesmo deletar o produto {{stockToDelete}}? </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDeleteStock()"
          >
            Confirmar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false; stockToDelete = ''"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="dialogQrCode"
      max-width="290"
    >
      <v-card>
        <v-card-text >
          <qr-code
            size="250" 
            :text=qrLocation
          >
          </qr-code>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogQrCode = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <add-fixed-button @clicked="addStock()" />

    <v-card-text style="height: 100px; position: relative">

    </v-card-text>

    <v-row>
      <v-col 
        cols="12"
      >
        <base-material-card
          color="indigo"
          icon="mdi-camera-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              Estoque
            </div>
          </template>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto"
            label="Pesquisar"
            hide-details
            single-line
            style="max-width: 250px;"
          />

          <v-divider class="mt-3" />

          <v-data-table
            :headers="headers"
            :items="items"
            :search.sync="search"
            :sort-desc="[false, true]"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            multi-sort
          >
            <template v-slot:item="row">
              <tr>
                <td class="button">{{row.item.id}}</td>
                <td class="button">{{row.item.name}}</td>
                <td class="button">{{row.item.brand}}</td>
                <td class="button">{{row.item.price}}</td>
                <td class="button">
                  <v-icon v-if="row.item.qty <= row.item.low_stock" color="red">mdi-alert</v-icon>
                  {{row.item.qty}}
                </td>
                <td class="button">{{row.item.low_stock}}</td>
                <td class="button">
                  <v-btn
                    icon
                    outlined
                    color="indigo"
                    @click="triggerQrCode(row.item.location)"
                  >
                    <v-icon>mdi-qrcode</v-icon>
                  </v-btn>
                </td>
                <td class="button">{{row.item.modified}}</td>
                <td>
                  <v-btn
                    class="mr-2"
                    outlined
                    fab
                    x-small
                    color="info"
                    @click="editStock(row.item.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="user.admin"
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="error"
                    @click="deleteStock(row.item.id)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                        
                </td>

              </tr>
            </template>

          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import StocksService from '@/services/StocksService'
  import dayjs from 'dayjs'

  export default {
    name: 'StocksIndex',

    components: {
      AddFixedButton: () => import('@/components/base/AddFixedButton'),
    },

    data () {
      return {
        dialog: "",
        stockToDelete:"",
        qrLocation: "",
        dialogQrCode: false,
        search: '',
        items: [],
        headers: [
        {
          text: '#',
          value: 'id',
        },
        {
          text: 'Nome',
          value: 'name',
        },
        {
          text: 'Marca',
          value: 'brand',
        },
        {
          text: 'Custo',
          value: 'price',
        },
        {
          text: 'Quantidade',
          value: 'qty',
        },
        {
          text: 'Estoque Baixo',
          value: 'low_stock',
        },
        {
          text: 'Local',
          value: 'location',
        },
        {
          text: 'Modificado',
          value: 'modified',
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
        },
      ],
      }
    },

    mounted () {
      this.getAllStocks()
    },

    computed: {
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    },

    methods: {

      triggerQrCode(code) {
        this.qrLocation =  code
        this.dialogQrCode = true
      },

      addStock () {
        this.$router.push({name: 'Criar Produto'})
      },

      editStock (id) {
        this.$router.push('/stocks/edit/'+id)
      },

      confirmDeleteStock(){
        const id = this.stockToDelete
        OrdersService.deleteOrder(id)
        .then(response => {
          this.items = this.items.filter(element => element.id != id)
          this.$toast.success('Ordem deletado.', '',{position:'topRight'})
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao deletar.', '',{position:'topRight'}) 
        })
        this.dialog = false
      },

      confirmDeleteStock () {
        const id = this.stockToDelete
        StocksService.deleteProduct(id)
        .then(response => {
          this.items = this.items.filter(element => element.id != id)
          this.$toast.success('Produto deletado.', '',{position:'topRight'})
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao deletar.', '',{position:'topRight'}) 
        })
      },

      deleteStock (id) {
        this.dialog = true
        this.stockToDelete = id
      },

      getAllStocks (page) {
        StocksService.getProducts()
        .then(response => {
          this.items = []
          let obj = {}
          response.data.products.forEach(element => {

            obj = {
              id: element.id,
              name: element.name,
              brand: element.brand,
              qty: element.qty,
              low_stock: element.low_stock,
              price: element.price,
              location: element.location,
              modified: dayjs(element.modified).format('DD/MM/YY'),
            }
            if(element.qty <= element.low_stock){
              this.$toast.warning('Produto '+element.name+' está com estoque baixo', '',{position:'topRight'})
            }
            this.items.push(obj);
          });
        })
        .catch(error => console.log(error))
      },
    },

  }
</script>
